import {Suspense , lazy} from "react";
import "react-slideshow-image/dist/styles.css";
import "./styles/main.scss";
import "./styles/global.scss";
import "./styles/gallery.scss";
import "./styles/navbar.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const Layout = lazy(() => import("./components/Layout/Layout"))
const Home = lazy(() => import('./pages/Home/Home'));
const ServiceDetails = lazy(() => import('./pages/ServiceDetails/ServiceDetails'));
const AboutUs = lazy(() => import('./pages/AboutUs/AboutUs'));
const Gallery = lazy(() => import('./pages/Gallery/Gallery'));
const Services = lazy(() => import('./pages/Services/Services'));
const Contacts = lazy(() => import('./pages/Contacts/Contacts'));

function App() {
  return (
    <BrowserRouter>
      <div className="page-wrapper">
	<Suspense fallback={<h1>Loading...</h1>}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path={"services"} element={<Services />} />
            <Route path={"about-us"} element={<AboutUs />} />
            <Route path={"contacts"} element={<Contacts />} />
            <Route path={"gallery"} element={<Gallery />} />
            <Route path={"services/:name"} element={<ServiceDetails />} />
          </Route>
        </Routes>
	</Suspense >
      </div>
    </BrowserRouter>
  );
}

export default App;
